import React, { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { connect } from "./redux/blockchain/blockchainActions";
import { fetchData } from "./redux/data/dataActions";
import * as s from "./styles/globalStyles";
import styled from "styled-components";
import cdcstyle from "./styles/cdc_style.css";
import minusBtn from "./images/plusBtn.png";
import plusBtn from "./images/plusBtn.png";
import spotlight from "./images/Spotlight-roadmap.png";
import amountdisplay from "./images/amountdisplay.png";

const truncate = (input, len) =>
  input.length > len ? `${input.substring(0, len)}...` : input;

const truncateUser = (input, len) =>
  input.length !== 0
    ? `${input.substring(0, 5)}...${input.substring(len - 4, len)}`
    : input;

export const StyledButton = styled.button`
  padding: 10px;
  border-radius: 50px;
  border: none;
  background-color: var(--secondary);
  padding: 10px;
  font-weight: bold;
  // color: var(--secondary-text);
  width: 100px;
  cursor: pointer;
  box-shadow: 0px 6px 0px -2px rgba(250, 250, 250, 0.3);
  -webkit-box-shadow: 0px 6px 0px -2px rgba(250, 250, 250, 0.3);
  -moz-box-shadow: 0px 6px 0px -2px rgba(250, 250, 250, 0.3);
  :active {
    box-shadow: none;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
  }
`;
export const StyledButtonConnect = styled.button`
  padding: 10px;
  border-radius: 50px;
  border: none;
  background-color: var(--secondary);
  padding: 10px;
  font-weight: bold;
  // color: var(--secondary-text);
  width: 100px;
  cursor: pointer;
  box-shadow: 0px 6px 0px -2px rgba(250, 250, 250, 0.3);
  -webkit-box-shadow: 0px 6px 0px -2px rgba(250, 250, 250, 0.3);
  -moz-box-shadow: 0px 6px 0px -2px rgba(250, 250, 250, 0.3);
  :active {
    box-shadow: none;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
  }
`;
export const StyledButtonMint = styled.button`
  padding: 10px;
  border-radius: 50px;
  border: 10px white;
  background-color: #d4b480;
  padding: 10px;
  font-weight: bold;
  font-size: 20px;
  width: 100px;
  cursor: pointer;
  box-shadow: 0px 6px 0px -2px rgba(250, 250, 250, 0.3);
  -webkit-box-shadow: 0px 6px 0px -2px rgba(250, 250, 250, 0.3);
  -moz-box-shadow: 0px 6px 0px -2px rgba(250, 250, 250, 0.3);
  :active {
    box-shadow: none;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
  }
`;
export const StyledRoundButton = styled.button`
  padding: 10px;
  // border-radius: 100%;
  border: none;
  // background-color: var(--primary);
  // background-image: url(${minusBtn});
  padding: 10px;
  font-weight: bold;
  font-size: 15px;
  color: var(--primary-text);
  width: 55 px;
  height: 55px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0px 4px 0px -2px rgba(250, 250, 250, 0.3);
  -webkit-box-shadow: 0px 4px 0px -2px rgba(250, 250, 250, 0.3);
  -moz-box-shadow: 0px 4px 0px -2px rgba(250, 250, 250, 0.3);
  :active {
    box-shadow: none;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
  }
`;
export const StyledRoundButton_2 = styled.button`
  // padding: 10px;
  // border-radius: 100%;
  border: none;
  // background-color: var(--primary);
  background-image: url(${minusBtn});
  background-repeat: no-repeat;
  padding: 10px;
  font-weight: bold;
  font-size: 15px;
  color: var(--primary-text);
  width: 100%;
  height: 50vh;
  cursor: pointer;
  // display: flex;
  // align-items: center;
  // justify-content: center;
  box-shadow: 0px 4px 0px -2px rgba(250, 250, 250, 0.3);
  -webkit-box-shadow: 0px 4px 0px -2px rgba(250, 250, 250, 0.3);
  -moz-box-shadow: 0px 4px 0px -2px rgba(250, 250, 250, 0.3);
  :active {
    box-shadow: none;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
  }
`;
export const ContainerAmount = styled.div`
  display: flex;
  flex: ${({ flex }) => (flex ? flex : 0)};
  flex-direction: ${({ fd }) => (fd ? fd : "column")};
  justify-content: ${({ jc }) => (jc ? jc : "flex-start")};
  align-items: ${({ ai }) => (ai ? ai : "flex-start")};
  background-color: ${({ test }) => (test ? "pink" : "none")};
  width: 100%;
  //  background-image: url(${amountdisplay});
  background-repeat: no-repeat;
  z-index: 24;
`;
export const AmountDisplay = styled.div`
  color: var(--primary-text);
  font-size: 30px;
  width: 100px;
  background-color: black;
  z-index: 30;
  padding-top: 12px;
  padding-bottom: 13px;
  border-top: 2px white solid;
  border-bottom: 2px white solid;
  margin-bottom: 2.5px;
`;
export const ResponsiveWrapper = styled.div`
  // display: flex;
  // flex: 1;
  // flex-direction: column;
  // justify-content: stretched;
  // align-items: stretched;
  // width: 50%;
  @media (min-width: 767px) {
    flex-direction: row;
  }
`;

export const StyledLogo = styled.img`
  width: 200px;
  @media (min-width: 767px) {
    width: 200px;
  }
  transition: width 0.5s;
  transition: height 0.5s;
`;

export const StyledImg = styled.img`
  box-shadow: 0px 5px 11px 2px rgba(0, 0, 0, 0.7);
  border: 4px dashed var(--secondary);
  background-color: var(--accent);
  border-radius: 100%;
  width: 200px;
  @media (min-width: 900px) {
    width: 250px;
  }
  @media (min-width: 1000px) {
    width: 300px;
  }
  transition: width 0.5s;
`;

export const StyledLink = styled.a`
  color: var(--secondary);
  text-decoration: none;
`;
export const StyledLinkAddress = styled.a`
  color: var(--secondary);
  text-decoration: none;
`;
export const AdressDiv = styled.a`
  padding-top: 30px;
`;
export const RoadmapContainer = styled.div`
  width: 100%;
  height: 100%;
  background-color: #000000;
  justify-content: center;
  background-image: url(${spotlight});
  background-repeat: no-repeat;
  background-position: center top;
  background-size: contain;
`;
export const TeamContainer = styled.div`
  width: 100%;
  height: 100%;
  background-color: #000000;
  justify-content: center;
  // background-image: url(${spotlight});
  background-repeat: no-repeat;
  background-position: center top;
  background-size: contain;
`;

function App() {
  const dispatch = useDispatch();
  const blockchain = useSelector((state) => state.blockchain);
  const data = useSelector((state) => state.data);
  const [claimingNft, setClaimingNft] = useState(false);
  const [feedback, setFeedback] = useState(`Click mint to buy your NFT.`);
  const [mintAmount, setMintAmount] = useState(1);
  const [CONFIG, SET_CONFIG] = useState({
    CONTRACT_ADDRESS: "",
    SCAN_LINK: "",
    NETWORK: {
      NAME: "",
      SYMBOL: "",
      ID: 0,
    },
    NFT_NAME: "",
    SYMBOL: "",
    MAX_SUPPLY: 1,
    WEI_COST: 0,
    DISPLAY_COST: 0,
    GAS_LIMIT: 0,
    MARKETPLACE: "",
    MARKETPLACE_LINK: "",
    SHOW_BACKGROUND: false,
  });
  const [faqAns, SetAnswerShow] = useState({
    1: false,
    2: false,
    3: false,
    4: false,
    5: false,
    6: false,
    7: false,
    // [false,false,false,false,false,false,false]
  });

  const SetAsw = (queNumb, e) => {
    let faqcopies = { ...faqAns };
    faqcopies[queNumb] = !faqcopies[queNumb];
    SetAnswerShow({ ...faqcopies });
    // console.log(faqAns[1])
  };

  var endDate = moment.utc("2022-04-17 13:00").valueOf();

  var timer = setInterval(function () {
    let now = moment.utc(new Date()).valueOf();
    let t = endDate - now;

    if (t >= 0) {
      let days = Math.floor(t / (1000 * 60 * 60 * 24));
      let hours = Math.floor((t % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
      let mins = Math.floor((t % (1000 * 60 * 60)) / (1000 * 60));
      let secs = Math.floor((t % (1000 * 60)) / 1000);
      (document.getElementById("days").innerText = days),
        (document.getElementById("hours").innerText = hours),
        (document.getElementById("minutes").innerText = mins),
        (document.getElementById("seconds").innerText = secs);
    } else {
      document.getElementById("mint-not-live").classList.add("hidden");
      document.getElementById("headline").innerText = "Minting has ended";
      document.getElementById("headline").style.fontSize = "2em";
      document.getElementById("countdown").style.display = "none";
      document.getElementById("content").style.display = "flex";
      document.getElementById("mintSwitch").classList.remove("hidden");
      document.getElementById("mintSwitch2").classList.remove("hidden");
      document.getElementById("mintSwitchNav").classList.remove("hidden");

      // document.getElementById("public-minting-desc").classList.add('hidden');
      // document.getElementById("minting-is-live").classList.remove('hidden');
      clearInterval(timer);
    }
  }, 0);

  const ShowNavMenu = () => {
    let elmnt = document.getElementById("nav-menu");
    if (elmnt) {
      if (elmnt.classList.contains("navbar-mobile-menu")) {
        elmnt.classList.remove("navbar-mobile-menu");
      } else {
        elmnt.classList.add("navbar-mobile-menu");
      }
    }
  };

  const ScrollTo = (id) => {
    let elmnt = document.getElementById(id);
    let navmenu = document.getElementById("nav-menu");

    if (elmnt) {
      elmnt.scrollIntoView({ behavior: "smooth" });
      navmenu.classList.add("navbar-mobile-menu");
    }
  };

  const claimNFTs = () => {
    let cost = CONFIG.WEI_COST;
    let gasLimit = CONFIG.GAS_LIMIT;
    let totalCostWei = String(cost * mintAmount);
    let totalGasLimit = String(gasLimit * mintAmount);
    console.log("Cost: ", totalCostWei);
    console.log("Gas limit: ", totalGasLimit);
    setFeedback(`Minting your ${CONFIG.NFT_NAME}...`);
    setClaimingNft(true);
    blockchain.smartContract.methods
      .mintCDCs(mintAmount)
      .send({
        gasLimit: String(totalGasLimit),
        to: CONFIG.CONTRACT_ADDRESS,
        from: blockchain.account,
        value: totalCostWei,
      })
      .once("error", (err) => {
        console.log(err);
        setFeedback("Sorry, something went wrong please try again later.");
        setClaimingNft(false);
      })
      .then((receipt) => {
        console.log(receipt);
        setFeedback(
          `WOW, the ${CONFIG.NFT_NAME} NFT is yours! Go visit Opensea.io to view it.`
        );
        setClaimingNft(false);
        dispatch(fetchData(blockchain.account));
      });
  };

  const decrementMintAmount = () => {
    let newMintAmount = mintAmount - 1;
    if (newMintAmount < 1) {
      newMintAmount = 1;
    }
    setMintAmount(newMintAmount);
  };

  const incrementMintAmount = () => {
    let newMintAmount = mintAmount + 1;
    if (newMintAmount > 10) {
      newMintAmount = 10;
    }
    setMintAmount(newMintAmount);
  };

  const getData = () => {
    if (blockchain.account !== "" && blockchain.smartContract !== null) {
      dispatch(fetchData(blockchain.account));
    }
  };

  const getConfig = async () => {
    const configResponse = await fetch("/config/config.json", {
      cache: "no-cache",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    });
    const config = await configResponse.json();
    SET_CONFIG(config);
  };

  useEffect(() => {
    getConfig();
  }, []);

  useEffect(() => {
    getData();
  }, [blockchain.account]);

  class CDC extends React.Component {
    render() {
      return (
        <div className="main-container" id="MainContainer">
          {/* NAV BAR */}

          <div className="navbar container">
            <div className="navbar-mobile">
              <img
                className="mobilelogo"
                onClick={() => {
                  ScrollTo("home");
                }}
                src={"/config/images/mobilelogo.svg"}
                alt=""
              />
              {/* width=30% 待新增CSS*/}
              <img
                className=""
                onClick={() => {
                  ShowNavMenu();
                }}
                src={"/config/images/menuicon.svg"}
                alt=""
              />
            </div>

            <div className="navbar-wrapper navbar-mobile-menu" id="nav-menu">
              <div className="blank-left-nav">
                <div className="nav-logo mobilehide">
                  <img
                    className="web-logo"
                    src="/config/images/CDC_LOGO_WHITE.png"
                    alt=""
                  />
                </div>
              </div>
              <div className="nav-menu">
                {/* <div className="nav-logo mobilehide">
                        <img className="web-logo" src="/config/images/CDC_LOGO_WHITE.png"  alt=""/>
                    </div> */}
                <a
                  className="nav-item"
                  onClick={() => {
                    ScrollTo("home");
                  }}
                >
                  Home
                </a>
                <a
                  className="nav-item"
                  onClick={() => {
                    ScrollTo("desc");
                  }}
                >
                  Discover
                </a>
                <a
                  className="nav-item"
                  onClick={() => {
                    ScrollTo("mint"), ScrollTo("mintmobile");
                  }}
                >
                  Mint
                </a>
                <a
                  className="nav-item"
                  onClick={() => {
                    ScrollTo("roadMap");
                  }}
                >
                  Roadmap
                </a>
                <a
                  className="nav-item"
                  onClick={() => {
                    ScrollTo("team");
                  }}
                >
                  Team
                </a>
                <a
                  className="nav-item"
                  onClick={() => {
                    ScrollTo("faq");
                  }}
                >
                  FAQ
                </a>
                <div id="mintSwitchNav" className="hidden">
                  {/* WALLET BUTTON */}
                  {blockchain.account !== null ? (
                    <a className="nav-item">
                      <StyledButton>
                        {truncateUser(
                          blockchain.account,
                          blockchain.account.length
                        )}
                      </StyledButton>
                    </a>
                  ) : (
                    <a className="nav-item">
                      <StyledButton
                        onClick={(e) => {
                          e.preventDefault();
                          dispatch(connect());
                          getData();
                        }}
                      >
                        CONNECT WALLET
                      </StyledButton>
                    </a>
                  )}
                  {/* WALLET BUTTON END*/}
                </div>
              </div>
              <div className="media-icon">
                <a
                  href="https://discord.gg/gTbaX7aaTg"
                  target="_blank"
                  className="media-item"
                >
                  <img
                    src={"/config/images/discord-alt.svg"}
                    alt="Background"
                  />
                </a>
                <a
                  href="https://twitter.com/CoolDuckClub"
                  target="_blank"
                  className="media-item"
                >
                  <img src={"/config/images/twitter.svg"} alt="Background" />
                </a>
                <a
                  href="https://www.instagram.com/coolduckclub/?utm_medium=copy_link"
                  target="_blank"
                  className="media-item"
                >
                  <img src={"/config/images/instagram.svg"} alt="Background" />
                </a>
              </div>
            </div>
          </div>
          {/* WALLET BUTTON */}
          {/* {blockchain.account!== null?(
                    <a className="connectBtn pchideConnect"><StyledButton>{truncateUser(blockchain.account,blockchain.account.length)}</StyledButton></a>
                    ): 
                    <a className="connectBtn pchideConnect"> 
                    <StyledButton
                      onClick={(e) => {
                        e.preventDefault();
                        dispatch(connect());
                        getData();
                      }}
                    >
                      CONNECT WALLET
                    </StyledButton>
                    </a>
            } */}
          {/* WALLET BUTTON END*/}
          {/* 首頁大圖 */}
          <div id="home"></div>
          <div className="landing-page-pc container-new mobilehide">
            <img
              src="/config/images/CDC_fittingroom.jpg"
              id="web-banner"
              alt=""
            />
          </div>
          <div className="landing-page-mobile container-new pchide">
            <img
              src="/config/images/CDC_fittingroom2.jpg"
              id="web-banner-mobile"
              alt=""
            />
          </div>
          <div className="landing-page container" id="desc">
            <div className="nft-title-container">
              <h1 className="title-header">Welcome to </h1>
              <h1 className="title-header">
                <div className="the-CDC-underline-container">
                  Cool Duck Club<div className="the-CDC-underline"></div>
                </div>{" "}
                NFT
              </h1>
              <div className="title-info-container">
                <p className="title-info">
                  Limited Minting Coming Soon. #1200 Exclusive Cool Duck NFT
                  Project
                </p>
              </div>
              {/* Timer */}
              <div className="mint-timer">
                <h1 id="headline">Countdown to Public Mint</h1>
                <div id="countdown">
                  <ul>
                    <li>
                      <span id="days"></span>days
                    </li>
                    <li>
                      <span id="hours"></span>Hours
                    </li>
                    <li>
                      <span id="minutes"></span>Minutes
                    </li>
                    <li>
                      <span id="seconds"></span>Seconds
                    </li>
                  </ul>
                </div>
                <br></br>
                <div id="content" className="mint-btn">
                  <a
                    class="btn-type-2"
                    href="https://opensea.io/collection/coolduckclub"
                    target="_blank"
                  >
                    View on Opensea!
                  </a>
                  {/* <div class="btn-spacer-h"></div> */}
                  {/* <a class="btn-type-1" href="/mint.html" target="_blank">Mint Now</a> */}
                </div>
              </div>
              {/* Timer End*/}
              <div className="title-button-container" id="mint-not-live">
                <div className="btn-spacer-h"></div>
                {/* <a className="btn-type-2" href="https://discord.gg/gTbaX7aaTg" target="_blank">Join Our
                        Discord</a> */}
                <a
                  class="btn-type-2"
                  href="https://opensea.io/collection/coolduckclub"
                  target="_blank"
                >
                  View on Opensea!
                </a>
              </div>
            </div>

            <div className="nft-showcase-container">
              <div>
                <img
                  className="unknowduck"
                  src={"/config/images/mysterybox.gif"}
                  alt=""
                />
              </div>
            </div>
          </div>
          {/* 首頁大圖 END*/}
          <div className="ducks-imgwrapper">
            <div className="ducks-imgset">
              <img
                className="ducks-img"
                src={"/config/images/banners-template-DUCK2.jpg"}
                alt=""
              />
            </div>
          </div>
          {/* noMINT模組區 */}
          <div className="mintmobilehide" id="mint">
            <s.ContainerBottom
              style={{
                backgroundColor: "black",
              }}
            >
              <s.ContainerVM style={{}}>
                <img
                  src={"/config/images/gramophone_record2.png"}
                  alt="Background"
                  className="CD-img"
                />
              </s.ContainerVM>
              <s.ContainerDisc style={{}}>
                <img
                  src={"/config/images/disc2.png"}
                  alt="Background"
                  className="disc-img"
                />
              </s.ContainerDisc>
              <s.ContainerCD>
                <img
                  src={"/config/images/CD.jpg"}
                  alt="Background"
                  className="CD-img"
                />
              </s.ContainerCD>

              <s.ContainerMint>
                <ResponsiveWrapper>
                  <s.TextTitle
                    className="mintTitle"
                    style={{
                      textAlign: "left",
                      fontWeight: "bold",
                      color: "var(--accent-text)",
                    }}
                  >
                    GET YOUR DUCK
                  </s.TextTitle>
                  <s.TextSubTitle
                    className="mintSubTitle"
                    style={{
                      textAlign: "left",
                      fontWeight: "bold",
                      color: "var(--accent-text)",
                    }}
                  >
                    ENTER THE AMOUNT<br></br> OF DUCK(S) YOU<br></br> WOULD LIKE
                    TO PURCHASE.
                  </s.TextSubTitle>
                  <s.TextDescription
                    style={{
                      textAlign: "left",
                      color: "var(--primary-text)",
                    }}
                  >
                    <div id="mintSwitch" className="hidden">
                      {/* 合約地址 */}
                      <StyledLinkAddress
                        target={"_blank"}
                        href={CONFIG.SCAN_LINK}
                        className=""
                      >
                        CONTRACT ADDRESS:<br></br>
                        {truncate(CONFIG.CONTRACT_ADDRESS, 20)}
                        {/* --------------------------- */}
                      </StyledLinkAddress>
                      {/* WALLET BUTTON */}
                      {blockchain.account !== null ? (
                        <a className="nav-item">
                          <StyledButton>
                            {truncateUser(
                              blockchain.account,
                              blockchain.account.length
                            )}
                          </StyledButton>
                        </a>
                      ) : (
                        <a className="nav-item">
                          <StyledButton
                            onClick={(e) => {
                              e.preventDefault();
                              dispatch(connect());
                              getData();
                            }}
                          >
                            CONNECT WALLET
                          </StyledButton>
                        </a>
                      )}
                      {/* WALLET BUTTON END*/}
                      {/* 合約地址 END*/}
                    </div>
                  </s.TextDescription>
                  <s.SpacerSmall />
                  {Number(data.totalSupply) >= CONFIG.MAX_SUPPLY ? (
                    <>
                      <s.TextPriceTitle
                        style={{
                          textAlign: "left",
                          color: "var(--accent-text)",
                        }}
                      >
                        Whitelist sale has ended.
                      </s.TextPriceTitle>
                      <s.TextDescription
                        style={{
                          textAlign: "left",
                          color: "var(--accent-text)",
                        }}
                      >
                        You can still mint {CONFIG.NFT_NAME} NFT on upcoming
                        public sale!
                      </s.TextDescription>
                      <s.SpacerSmall />
                      <StyledLink
                        target={"_blank"}
                        href={CONFIG.MARKETPLACE_LINK}
                      >
                        Click here to view Cool Ducks on {CONFIG.MARKETPLACE}!
                      </StyledLink>
                    </>
                  ) : (
                    <>
                      <s.TextPriceTitle
                        className="mintPriceTitle"
                        style={{
                          textAlign: "left",
                          color: "var(--accent-text)",
                          fontSize: 35,
                          fontWeight: "bold",
                          color: "#e3b839",
                        }}
                      >
                        Public Mint<br></br>
                        {CONFIG.DISPLAY_COST}
                        <a className="perNFT"> {CONFIG.NETWORK.SYMBOL}/NFT</a>
                        <a className="gasFeeNotice"> *Excluding gas fees.</a>
                      </s.TextPriceTitle>
                      {/* <s.GasFeeDescription
                  style={{ textAlign: "left", color: "var(--accent-text)" }}
                >
                  *Excluding gas fees.
                </s.GasFeeDescription> */}
                      <s.SpacerSmall />
                      {blockchain.account === "" ||
                      blockchain.smartContract === null ? (
                        <s.Container ai={"left"} jc={"center"}>
                          <s.TextDescription
                            style={{
                              textAlign: "left",
                              color: "var(--accent-text)",
                            }}
                          >
                            Connect to the {CONFIG.NETWORK.NAME} network
                          </s.TextDescription>
                          <s.SpacerSmall />
                          {/* 錯誤訊息 */}
                          {blockchain.errorMsg !== "" ? (
                            <>
                              <s.SpacerSmall />
                              <s.TextDescription
                                style={{
                                  textAlign: "center",
                                  color: "var(--accent-text)",
                                }}
                              >
                                {blockchain.errorMsg}
                              </s.TextDescription>
                            </>
                          ) : null}
                          {/* 錯誤訊息 End*/}
                        </s.Container>
                      ) : (
                        <>
                          <s.TextDescription
                            style={{
                              textAlign: "left",
                              color: "var(--accent-text)",
                            }}
                          >
                            {feedback}
                          </s.TextDescription>
                          {/* <s.SpacerMedium /> */}
                          <ContainerAmount ai={"center"} jc={"left"} fd={"row"}>
                            <div
                              disabled={claimingNft ? 1 : 0}
                              className="minusBtn"
                              onClick={(e) => {
                                e.preventDefault();
                                decrementMintAmount();
                              }}
                            >
                              <img
                                className="amountBtn"
                                src={"/config/images/minusBtn.png"}
                                alt=""
                              />
                            </div>
                            <div className="amount-wrapper">
                              <AmountDisplay
                                style={{
                                  textAlign: "center",
                                  color: "var(--accent-text)",
                                }}
                              >
                                {mintAmount}
                              </AmountDisplay>
                            </div>
                            <div
                              className="plusBtn"
                              disabled={claimingNft ? 1 : 0}
                              onClick={(e) => {
                                e.preventDefault();
                                incrementMintAmount();
                              }}
                            >
                              <img
                                className="amountBtn"
                                src={"/config/images/plusBtn.png"}
                                alt=""
                              />
                            </div>
                            <StyledButtonMint
                              disabled={claimingNft ? 1 : 0}
                              onClick={(e) => {
                                e.preventDefault();
                                claimNFTs();
                                getData();
                              }}
                            >
                              {claimingNft ? "BUSY" : "MINT"}
                            </StyledButtonMint>
                          </ContainerAmount>
                          {/* <s.TextSubTitle2 className="mintLimit">
                      5 MAX PER ADDRESS
                    </s.TextSubTitle2> */}
                        </>
                      )}
                    </>
                  )}
                  <s.SpacerMedium />
                  <s.SpacerLarge />
                  {/* <s.Container flex={1} jc={"center"} ai={"center"}>
            <StyledImg
              alt={"example"}
              src={"/config/images/example.gif"}
              style={{ transform: "scaleX(-1)" }}
            />
          </s.Container> */}
                </ResponsiveWrapper>
              </s.ContainerMint>
              {/* 注意事項 (暫時不放) */}
              {/* <s.Notice
            style={{
              textAlign: "left",
              color: "white",
            }}
          >
            • Please make sure you are connected to the right network (
            {CONFIG.NETWORK.NAME} Mainnet) and the correct address. Please note:
            Once you make the purchase, you cannot undo this action.
          </s.Notice>
          <s.SpacerSmall />
          <s.Notice
            style={{
              textAlign: "left",
              color: "white",
            }}
          >
            • We have set the gas limit to {CONFIG.GAS_LIMIT} for the contract to
            successfully mint your NFT. We recommend that you don't lower the
            gas limit.
        </s.Notice> */}
            </s.ContainerBottom>
          </div>
          <div className="mintpchide" id="mintmobile">
            <s.ContainerBottomMobile2>
              <s.ContainerMintMobile>
                <ResponsiveWrapper>
                  <s.TextTitle
                    className="mintTitle"
                    style={{
                      textAlign: "left",
                      fontWeight: "bold",
                      color: "var(--accent-text)",
                    }}
                  >
                    GET YOUR DUCK
                  </s.TextTitle>
                  <s.TextSubTitle
                    className="mintSubTitle"
                    style={{
                      textAlign: "left",
                      fontWeight: "bold",
                      color: "var(--accent-text)",
                    }}
                  >
                    ENTER THE AMOUNT<br></br> OF DUCK(S) YOU<br></br> WOULD LIKE
                    TO PURCHASE.
                  </s.TextSubTitle>

                  <s.TextDescription
                    style={{
                      textAlign: "left",
                      color: "var(--primary-text)",
                    }}
                  >
                    <div id="mintSwitch2" className="hidden">
                      {/* 合約地址 */}
                      <StyledLinkAddress
                        target={"_blank"}
                        href={CONFIG.SCAN_LINK}
                        className=""
                      >
                        CONTRACT ADDRESS:<br></br>
                        {truncate(CONFIG.CONTRACT_ADDRESS, 20)}
                        {/* --------------------------- */}
                      </StyledLinkAddress>
                      {/* WALLET BUTTON */}
                      {blockchain.account !== null ? (
                        <a className="nav-item">
                          <StyledButton>
                            {truncateUser(
                              blockchain.account,
                              blockchain.account.length
                            )}
                          </StyledButton>
                        </a>
                      ) : (
                        <a className="nav-item">
                          <StyledButton
                            onClick={(e) => {
                              e.preventDefault();
                              dispatch(connect());
                              getData();
                            }}
                          >
                            CONNECT WALLET
                          </StyledButton>
                        </a>
                      )}
                      {/* WALLET BUTTON END*/}
                      {/* 合約地址 END*/}
                    </div>
                  </s.TextDescription>
                  <s.SpacerSmall />
                  {/* MINT模組 */}
                  {Number(data.totalSupply) >= CONFIG.MAX_SUPPLY ? (
                    <>
                      <s.TextPriceTitle
                        style={{
                          textAlign: "center",
                          color: "var(--accent-text)",
                        }}
                      >
                        The sale has ended.
                      </s.TextPriceTitle>
                      <s.TextDescription
                        style={{
                          textAlign: "center",
                          color: "var(--accent-text)",
                        }}
                      >
                        You can still mint {CONFIG.NFT_NAME} NFT on upcoming
                        public sale!
                      </s.TextDescription>
                      <s.SpacerSmall />
                      <StyledLink
                        target={"_blank"}
                        href={CONFIG.MARKETPLACE_LINK}
                      >
                        Click here to view Cool Ducks on {CONFIG.MARKETPLACE}!
                      </StyledLink>
                    </>
                  ) : (
                    <>
                      <s.TextPriceTitle
                        className="mintPriceTitle"
                        style={{
                          textAlign: "left",
                          color: "var(--accent-text)",
                          fontSize: 35,
                          fontWeight: "bold",
                          color: "#e3b839",
                        }}
                      >
                        Whitelist Mint<br></br>
                        {CONFIG.DISPLAY_COST}
                        <a className="perNFT"> {CONFIG.NETWORK.SYMBOL}/NFT</a>
                        {/* <a className="gasFeeNotice">{" "}*Excluding gas fees.</a> */}
                      </s.TextPriceTitle>
                      <s.GasFeeDescription
                        style={{
                          textAlign: "left",
                          color: "var(--accent-text)",
                        }}
                      >
                        *Excluding gas fees.
                      </s.GasFeeDescription>
                      <s.SpacerSmall />
                      {blockchain.account === "" ||
                      blockchain.smartContract === null ? (
                        <s.Container ai={"left"} jc={"center"}>
                          <s.TextDescription
                            style={{
                              textAlign: "left",
                              color: "var(--accent-text)",
                            }}
                          >
                            Connect to the {CONFIG.NETWORK.NAME} network
                          </s.TextDescription>
                          <s.SpacerSmall />
                          {/* 錯誤訊息 */}
                          {blockchain.errorMsg !== "" ? (
                            <>
                              <s.SpacerSmall />
                              <s.TextDescription
                                style={{
                                  textAlign: "center",
                                  color: "var(--accent-text)",
                                }}
                              >
                                {blockchain.errorMsg}
                              </s.TextDescription>
                            </>
                          ) : null}
                          {/* 錯誤訊息 End*/}
                        </s.Container>
                      ) : (
                        <>
                          <s.TextDescription
                            style={{
                              textAlign: "left",
                              color: "var(--accent-text)",
                            }}
                          >
                            {feedback}
                          </s.TextDescription>
                          {/* <s.SpacerMedium /> */}
                          <ContainerAmount ai={"center"} jc={"left"} fd={"row"}>
                            <div
                              disabled={claimingNft ? 1 : 0}
                              className="minusBtn"
                              onClick={(e) => {
                                e.preventDefault();
                                decrementMintAmount();
                              }}
                            >
                              <img src={"/config/images/minusBtn.png"} alt="" />
                            </div>
                            <div className="amount-wrapper">
                              <AmountDisplay
                                style={{
                                  textAlign: "center",
                                  color: "var(--accent-text)",
                                }}
                              >
                                {mintAmount}
                              </AmountDisplay>
                            </div>
                            <div
                              className="plusBtn"
                              disabled={claimingNft ? 1 : 0}
                              onClick={(e) => {
                                e.preventDefault();
                                incrementMintAmount();
                              }}
                            >
                              <img src={"/config/images/plusBtn.png"} alt="" />
                            </div>
                            <StyledButtonMint
                              disabled={claimingNft ? 1 : 0}
                              onClick={(e) => {
                                e.preventDefault();
                                claimNFTs();
                                getData();
                              }}
                            >
                              {claimingNft ? "BUSY" : "MINT"}
                            </StyledButtonMint>
                          </ContainerAmount>
                          {/* <s.TextSubTitle2 className="mintLimit">
                      5 MAX PER ADDRESS
                    </s.TextSubTitle2> */}
                        </>
                      )}
                    </>
                  )}
                  {/* MINT模組 END*/}

                  <s.SpacerMedium />
                  {/* <s.SpacerLarge /> */}
                </ResponsiveWrapper>
              </s.ContainerMintMobile>
            </s.ContainerBottomMobile2>
            <s.ContainerBottomMobile
              id="mint"
              style={{
                backgroundColor: "black",
              }}
            >
              <s.ContainerVM style={{}}>
                <img
                  src={"/config/images/gramophone_record2.png"}
                  alt="Background"
                  className="VM-img"
                />
              </s.ContainerVM>
              <s.ContainerDisc>
                <img
                  src={"/config/images/disc2.png"}
                  alt="Background"
                  className="disc-mobileimg"
                />
              </s.ContainerDisc>
              <s.ContainerCD>
                <img
                  src={"/config/images/CD.jpg"}
                  alt="Background"
                  className="CD-img"
                />
              </s.ContainerCD>

              {/* 注意事項 (暫時不放) */}
              {/* <s.Notice
            style={{
              textAlign: "left",
              color: "white",
            }}
          >
            • Please make sure you are connected to the right network (
            {CONFIG.NETWORK.NAME} Mainnet) and the correct address. Please note:
            Once you make the purchase, you cannot undo this action.
          </s.Notice>
          <s.SpacerSmall />
          <s.Notice
            style={{
              textAlign: "left",
              color: "white",
            }}
          >
            • We have set the gas limit to {CONFIG.GAS_LIMIT} for the contract to
            successfully mint your NFT. We recommend that you don't lower the
            gas limit.
        </s.Notice> */}
            </s.ContainerBottomMobile>
          </div>
          {/* noMINT模組區 */}

          <div className="ducks-imgwrapper">
            <div className="ducks-imgset">
              <img
                className="ducks-img"
                src={"/config/images/banners-template-DUCK2.jpg"}
                alt=""
              />
              <img
                className="ducks-img mobilehide"
                src={"/config/images/ducks.jpg"}
                alt=""
              />
              <img
                className="ducks-img pchide"
                src={"/config/images/ducks-mobile.jpg"}
                alt=""
              />
            </div>
          </div>

          <div id="roadMap">
            <h2 className="roadmap-title">Roadmap</h2>
          </div>
          <RoadmapContainer>
            <div className="CDC-roadmap-wrapper container">
              <div className="roadmap-content">
                {/* <!-- SECOND --> */}
                <div className="roadmap-sets">
                  <div className="roadmap-set">
                    <div className="rm-wrapper rm3">
                      <div>
                        <img
                          className="check-logo"
                          src="config/images/Upcoming.png"
                          alt=""
                        />
                      </div>
                      <div className="romaptitle">LAND IN SANDBOX</div>
                      <div>
                        <img
                          className="comingbtn-logo"
                          src="config/images/button-coming-soon-before.png"
                          alt=""
                        />
                      </div>
                      <div className="romaptext">
                        <p>
                          Once the CDC all Sold out , CDC will buy dedicated
                          land in The Sandbox, to ensure our spot in the
                          metaverse.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="roadmap-set">
                    <div className="rm-wrapper rm4">
                      <div>
                        <img
                          className="check-logo"
                          src="config/images/Upcoming.png"
                          alt=""
                        />
                      </div>
                      <div className="romaptitle">Cool Ducks GameFi</div>
                      <div>
                        <img
                          className="comingbtn-logo"
                          src="config/images/button-coming-soon-before.png"
                          alt=""
                        />
                      </div>
                      <div className="romaptext">
                        <p>
                          We will develop a game inside this land called "Cool
                          Duck !" where CDC Holders can play and earn rewards.
                          Everyone owning at least 5 CDC 100 days after mint
                          (date TBA) will receive a FREE surprise airdrop.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                {/* <!-- SECOND END --> */}
                <div className="roadmap-line">
                  <img
                    className="rmline2"
                    src="config/images/spotlight-linelong.png"
                    alt=""
                  />
                </div>
                {/* <!-- THIRD --> */}
                <div className="roadmap-sets">
                  <div className="roadmap-set">
                    <div className="rm-wrapper rm5">
                      <div>
                        <img
                          className="check-logo"
                          src="config/images/Upcoming.png"
                          alt=""
                        />
                      </div>
                      <div className="romaptitle">ASSETS</div>
                      <div>
                        <img
                          className="comingbtn-logo"
                          src="config/images/button-coming-soon-before.png"
                          alt=""
                        />
                      </div>
                      <div className="romaptext">
                        <p>
                          CDC project will create avatars and items to be
                          integrated into the Metaverse. We aim to become a top
                          IP in the Metaverse, and we plan to have multiple
                          integrations in this space over the course of the next
                          few months.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="roadmap-set">
                    <div className="rm-wrapper rm6">
                      <div>
                        <img
                          className="stock-logo"
                          src="config/images/StockGIF.gif"
                          alt=""
                        />
                      </div>
                      <div className="romaptitle">CDC e-commerce platform</div>
                      <div>
                        <img
                          className="comingbtn-logo"
                          src="config/images/button-coming-soon-before.png"
                          alt=""
                        />
                      </div>
                      <div className="romaptext">
                        <p>
                          We will introduce major global brands<br></br>
                          1. Contact the exclusive agent.<br></br>
                          2.CoolDuck membership price.<br></br>
                          3. Non-members will also enjoy better prices than
                          physical channels.<br></br>
                          4. 50% of the surplus will be distributed to the
                          holders.<br></br>
                          5. Help members list their own brands.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                {/* <!-- THIRD END --> */}
                <div className="roadmap-line">
                  <img
                    className="rmline3"
                    src="config/images/spotlight-linelong.png"
                    alt=""
                  />
                </div>
                <div className="spldudckwrapper">
                  <img
                    className="spldudck"
                    src="config/images/Spotlight-Duck.png"
                    alt=""
                  />
                </div>
              </div>
            </div>
          </RoadmapContainer>
          <div className="ducks-imgwrapper">
            <div className="ducks-imgset">
              <img
                className="ducks-img"
                src={"/config/images/banners-template-DUCK2.jpg"}
                alt=""
              />
            </div>
          </div>
          <div id="team"></div>
          <TeamContainer className="teamContainer mobilehide">
            <h2 className="team-title">THE TEAM</h2>
            <s.ContainerTeam style={{ backgroundColor: "black" }}>
              <s.ContainerTeamItem>
                <s.TeamTitle
                  className="teamSubTitle"
                  style={{
                    textAlign: "center",
                    fontWeight: "bold",
                    color: "var(--accent-text)",
                  }}
                >
                  <img
                    className="teamImg"
                    src={"/config/images/CDC-MARS.jpg"}
                    alt=""
                  />
                  <s.TeamTitleText
                    style={{
                      color: "var(--accent-text)",
                    }}
                  >
                    Elder duck<br></br>
                    <p style={{ color: "#e4b366" }}>
                      (Strategy & Development)<br></br>
                    </p>
                    Mars<br></br>
                  </s.TeamTitleText>
                </s.TeamTitle>
              </s.ContainerTeamItem>
              <s.ContainerTeamItem>
                <s.TeamTitle
                  className="teamSubTitle"
                  style={{
                    textAlign: "center",
                    fontWeight: "bold",
                    color: "var(--accent-text)",
                  }}
                >
                  <img
                    className="teamImg"
                    src={"/config/images/CDC-ANNA.jpg"}
                    alt=""
                  />
                  <s.TeamTitleText
                    style={{
                      color: "var(--accent-text)",
                    }}
                  >
                    DaVinci duck<br></br>
                    <p style={{ color: "#e4b366" }}>
                      (Art & Designer)<br></br>
                    </p>
                    Anna<br></br>
                  </s.TeamTitleText>
                </s.TeamTitle>
              </s.ContainerTeamItem>
              <s.ContainerTeamItem>
                <s.TeamTitle
                  className="teamSubTitle"
                  style={{
                    textAlign: "center",
                    fontWeight: "bold",
                    color: "var(--accent-text)",
                  }}
                >
                  <img
                    className="teamImg"
                    src={"/config/images/CDC-RED.jpg"}
                    alt=""
                  />
                  <s.TeamTitleText
                    style={{
                      color: "var(--accent-text)",
                    }}
                  >
                    Nerd duck<br></br>
                    <p style={{ color: "#e4b366" }}>
                      (Frontend Engineer)<br></br>
                    </p>
                    Red<br></br>
                  </s.TeamTitleText>
                </s.TeamTitle>
              </s.ContainerTeamItem>
            </s.ContainerTeam>
            <s.ContainerTeam style={{ backgroundColor: "black" }}>
              <s.ContainerTeamItem>
                <s.TeamTitle
                  className="teamSubTitle"
                  style={{
                    textAlign: "center",
                    fontWeight: "bold",
                    color: "var(--accent-text)",
                  }}
                >
                  <img
                    className="teamImg"
                    src={"/config/images/CDC-K.jpg"}
                    alt=""
                  />
                  <s.TeamTitleText
                    style={{
                      color: "var(--accent-text)",
                    }}
                  >
                    Geek duck<br></br>
                    <p style={{ color: "#e4b366" }}>
                      (Blockchain Engineer)<br></br>
                    </p>
                    K<br></br>
                  </s.TeamTitleText>
                </s.TeamTitle>
              </s.ContainerTeamItem>
              <s.ContainerTeamItem>
                <s.TeamTitle
                  className="teamSubTitle"
                  style={{
                    textAlign: "center",
                    fontWeight: "bold",
                    color: "var(--accent-text)",
                  }}
                >
                  <img
                    className="teamImg"
                    src={"/config/images/CDC-RT.jpg"}
                    alt=""
                  />
                  <s.TeamTitleText
                    style={{
                      color: "var(--accent-text)",
                    }}
                  >
                    Party Boy duck<br></br>
                    <p style={{ color: "#e4b366" }}>
                      (Public Relations)<br></br>
                    </p>
                    RT<br></br>
                  </s.TeamTitleText>
                </s.TeamTitle>
              </s.ContainerTeamItem>
              <s.ContainerTeamItem>
                <s.TeamTitle
                  className="teamSubTitle"
                  style={{
                    textAlign: "center",
                    fontWeight: "bold",
                    color: "var(--accent-text)",
                  }}
                >
                  <img
                    className="teamImg"
                    src={"/config/images/CDC-PETER.jpg"}
                    alt=""
                  />
                  <s.TeamTitleText
                    style={{
                      color: "var(--accent-text)",
                    }}
                  >
                    Merchant duck<br></br>
                    <p style={{ color: "#e4b366" }}>
                      (Marketing)<br></br>
                    </p>
                    Peter<br></br>
                  </s.TeamTitleText>
                </s.TeamTitle>
              </s.ContainerTeamItem>
            </s.ContainerTeam>
          </TeamContainer>
          <TeamContainer className="teamContainer pchide">
            <h2 className="roadmap-title">THE TEAM</h2>
            <s.ContainerTeamM style={{ backgroundColor: "black" }}>
              <s.ContainerTeamItem>
                <s.TeamTitle
                  className="teamSubTitle"
                  style={{
                    textAlign: "center",
                    fontWeight: "bold",
                    color: "var(--accent-text)",
                  }}
                >
                  <img
                    className="teamImg"
                    src={"/config/images/CDC-MARS.jpg"}
                    alt=""
                  />
                  <s.TeamTitleTextM
                    style={{
                      color: "var(--accent-text)",
                    }}
                  >
                    Elder duck<br></br>
                    <p style={{ color: "#e4b366" }}>
                      (Strategy & Development)<br></br>
                    </p>
                    Mars<br></br>
                  </s.TeamTitleTextM>
                </s.TeamTitle>
              </s.ContainerTeamItem>
              <s.ContainerTeamItem>
                <s.TeamTitle
                  className="teamSubTitle"
                  style={{
                    textAlign: "center",
                    fontWeight: "bold",
                    color: "var(--accent-text)",
                  }}
                >
                  <img
                    className="teamImg"
                    src={"/config/images/CDC-ANNA.jpg"}
                    alt=""
                  />
                  <s.TeamTitleTextM
                    style={{
                      color: "var(--accent-text)",
                    }}
                  >
                    DaVinci duck<br></br>
                    <p style={{ color: "#e4b366" }}>
                      (Art & Designer)<br></br>
                    </p>
                    Anna<br></br>
                  </s.TeamTitleTextM>
                </s.TeamTitle>
              </s.ContainerTeamItem>
            </s.ContainerTeamM>
            <s.ContainerTeamM style={{ backgroundColor: "black" }}>
              <s.ContainerTeamItem>
                <s.TeamTitle
                  className="teamSubTitle"
                  style={{
                    textAlign: "center",
                    fontWeight: "bold",
                    color: "var(--accent-text)",
                  }}
                >
                  <img
                    className="teamImg"
                    src={"/config/images/CDC-RED.jpg"}
                    alt=""
                  />
                  <s.TeamTitleTextM
                    style={{
                      color: "var(--accent-text)",
                    }}
                  >
                    Nerd duck<br></br>
                    <p style={{ color: "#e4b366" }}>
                      (Frontend Engineer)<br></br>
                    </p>
                    Red<br></br>
                  </s.TeamTitleTextM>
                </s.TeamTitle>
              </s.ContainerTeamItem>
              <s.ContainerTeamItem>
                <s.TeamTitle
                  className="teamSubTitle"
                  style={{
                    textAlign: "center",
                    fontWeight: "bold",
                    color: "var(--accent-text)",
                  }}
                >
                  <img
                    className="teamImg"
                    src={"/config/images/CDC-K.jpg"}
                    alt=""
                  />
                  <s.TeamTitleTextM
                    style={{
                      color: "var(--accent-text)",
                    }}
                  >
                    Geek duck<br></br>
                    <p style={{ color: "#e4b366" }}>
                      (Blockchain Engineer)<br></br>
                    </p>
                    K<br></br>
                  </s.TeamTitleTextM>
                </s.TeamTitle>
              </s.ContainerTeamItem>
            </s.ContainerTeamM>
            <s.ContainerTeamM style={{ backgroundColor: "black" }}>
              <s.ContainerTeamItem>
                <s.TeamTitle
                  className="teamSubTitle"
                  style={{
                    textAlign: "center",
                    fontWeight: "bold",
                    color: "var(--accent-text)",
                  }}
                >
                  <img
                    className="teamImg"
                    src={"/config/images/CDC-RT.jpg"}
                    alt=""
                  />
                  <s.TeamTitleTextM
                    style={{
                      color: "var(--accent-text)",
                    }}
                  >
                    Party Boy duck<br></br>
                    <p style={{ color: "#e4b366" }}>
                      (Public Relations)<br></br>
                    </p>
                    RT<br></br>
                  </s.TeamTitleTextM>
                </s.TeamTitle>
              </s.ContainerTeamItem>
              <s.ContainerTeamItem>
                <s.TeamTitle
                  className="teamSubTitle"
                  style={{
                    textAlign: "center",
                    fontWeight: "bold",
                    color: "var(--accent-text)",
                  }}
                >
                  <img
                    className="teamImg"
                    src={"/config/images/CDC-PETER.jpg"}
                    alt=""
                  />
                  <s.TeamTitleTextM
                    style={{
                      color: "var(--accent-text)",
                    }}
                  >
                    Merchant duck<br></br>
                    <p style={{ color: "#e4b366" }}>
                      (Marketing)<br></br>
                    </p>
                    Peter<br></br>
                  </s.TeamTitleTextM>
                </s.TeamTitle>
              </s.ContainerTeamItem>
            </s.ContainerTeamM>
          </TeamContainer>

          <div id="faq" className="faq-banner">
            <img
              className="faq-banner"
              src="config/images/banners-template-FAQ2.jpg"
              alt=""
            />
          </div>
          <div className="faq-container">
            <div className="faq-wrapper container">
              <div className="faq-qa-wrapper">
                <div className="faq-item noSelect">
                  <div className="faq-item-wrapper">
                    <div
                      className="faq-question"
                      onClick={(e) => {
                        SetAsw(1);
                      }}
                    >
                      What Chain Is The CDC NFT On?
                      <div className="show-ans-btn">
                        <img
                          className={`arrow-btn ${
                            faqAns[1] ? "rotate-arrow" : ""
                          }`}
                          src="config/images/down-arrow.svg"
                          alt=""
                        />
                      </div>
                    </div>
                    {faqAns[1] ? (
                      <div className="faq-answer">
                        We are on Ethereum Blockchain.
                      </div>
                    ) : null}
                  </div>
                  <div className="line"></div>
                </div>
                <div className="faq-item noSelect">
                  <div className="faq-item-wrapper">
                    <div
                      className="faq-question"
                      onClick={() => {
                        SetAsw(2);
                      }}
                    >
                      What is the NFT total supply?
                      <div className="show-ans-btn">
                        <img
                          className={`arrow-btn ${
                            faqAns[2] ? "rotate-arrow" : ""
                          }`}
                          src="config/images/down-arrow.svg"
                          alt=""
                        />
                      </div>
                    </div>
                    {faqAns[2] ? (
                      <div className="faq-answer">
                        Total supply of 1200 NFTs.
                      </div>
                    ) : null}
                  </div>
                  <div className="line"></div>
                </div>
                <div className="faq-item noSelect">
                  <div className="faq-item-wrapper">
                    <div
                      className="faq-question"
                      onClick={(e) => {
                        SetAsw(3);
                      }}
                    >
                      When is the Cool Duck Club mint date?
                      <div className="show-ans-btn">
                        <img
                          className={`arrow-btn ${
                            faqAns[3] ? "rotate-arrow" : ""
                          }`}
                          src="config/images/down-arrow.svg"
                          alt=""
                        />
                      </div>
                    </div>
                    {faqAns[3] ? (
                      <div className="faq-answer">
                        Whitelist Mint: April 16, 2022<br></br>
                        Public Mint: April 17, 2022
                      </div>
                    ) : null}
                  </div>
                  <div className="line"></div>
                </div>
                <div className="faq-item noSelect">
                  <div className="faq-item-wrapper">
                    <div
                      className="faq-question"
                      onClick={(e) => {
                        SetAsw(4);
                      }}
                    >
                      What is the Mint Price for Cool Duck Club NFT?
                      <div className="show-ans-btn">
                        <img
                          className={`arrow-btn ${
                            faqAns[4] ? "rotate-arrow" : ""
                          }`}
                          src="config/images/down-arrow.svg"
                          alt=""
                        />
                      </div>
                    </div>
                    {faqAns[4] ? (
                      <div className="faq-answer">
                        The public mint price is 0.12 ETH.
                      </div>
                    ) : null}
                  </div>
                  <div className="line"></div>
                </div>
                <div className="faq-item noSelect">
                  <div className="faq-item-wrapper">
                    <div
                      className="faq-question"
                      onClick={(e) => {
                        SetAsw(5);
                      }}
                    >
                      How can I mint?
                      <div className="show-ans-btn">
                        <img
                          className={`arrow-btn ${
                            faqAns[5] ? "rotate-arrow" : ""
                          }`}
                          src="config/images/down-arrow.svg"
                          alt=""
                        />
                      </div>
                    </div>
                    {faqAns[5] ? (
                      <div className="faq-answer">
                        Add ethereum network in metamask and load up the wallet
                        with ETH tokens.
                      </div>
                    ) : null}
                  </div>
                  <div className="line"></div>
                </div>
                <div className="faq-item noSelect">
                  <div className="faq-item-wrapper">
                    <div
                      className="faq-question"
                      onClick={(e) => {
                        SetAsw(6);
                      }}
                    >
                      How many NFT can I buy?
                      <div className="show-ans-btn">
                        <img
                          className={`arrow-btn ${
                            faqAns[6] ? "rotate-arrow" : ""
                          }`}
                          src="config/images/down-arrow.svg"
                          alt=""
                        />
                      </div>
                    </div>
                    {faqAns[6] ? (
                      <div className="faq-answer">Only 5 nft per address.</div>
                    ) : null}
                  </div>
                  <div className="line"></div>
                </div>
                <div className="faq-item noSelect">
                  <div className="faq-item-wrapper">
                    <div
                      className="faq-question"
                      onClick={(e) => {
                        SetAsw(7);
                      }}
                    >
                      I have a suggestion for the project, how can I let you
                      guys know?
                      <div className="show-ans-btn">
                        <img
                          className={`arrow-btn ${
                            faqAns[7] ? "rotate-arrow" : ""
                          }`}
                          src="config/images/down-arrow.svg"
                          alt=""
                        />
                      </div>
                    </div>
                    {faqAns[7] ? (
                      <div className="faq-answer">
                        Share your thoughts in our Discord general chat.
                      </div>
                    ) : null}
                  </div>
                  <div className="line"></div>
                </div>
                <div className="faq-item noSelect">
                  <div className="faq-item-wrapper">
                    <div
                      className="faq-question"
                      onClick={(e) => {
                        SetAsw(8);
                      }}
                    >
                      I still have a question not answered in this FAQ, how can
                      I find out?
                      <div className="show-ans-btn">
                        <img
                          className={`arrow-btn ${
                            faqAns[8] ? "rotate-arrow" : ""
                          }`}
                          src="config/images/down-arrow.svg"
                          alt=""
                        />
                      </div>
                    </div>
                    {faqAns[8] ? (
                      <div className="faq-answer">
                        DM one of our discord community mods or managers.
                      </div>
                    ) : null}
                  </div>
                  <div className="line"></div>
                </div>
              </div>
            </div>
          </div>
          <div className="join-container container">
            <div className="join-container-wrapper">
              <h3 className="join-title-text">
                Join the Cool Duck Club today !
              </h3>
              <div className="btn-spacer-v"></div>
              <a
                className="btn-type-2"
                href="https://discord.gg/gTbaX7aaTg"
                target="_blank"
              >
                Join Our Discord
              </a>
            </div>
          </div>

          <div className="footer-container">
            <div className="footer-line"></div>
            <div className="footer-wrapper container">
              <div>
                <img
                  className="footer-logo"
                  src="config/images/CDC_LOGO_WHITE.png"
                  alt=""
                />
              </div>
              <div className="copyrights mobilehide">
                COPYRIGHT 2022 &copy;COOLDUCKCLUB.COM ALL RIGHTS RESERVED
              </div>
              <div className="copyrights pchide">
                COPYRIGHT 2022 &copy; <br></br> COOLDUCKCLUB.COM ALL RIGHTS
                RESERVED
              </div>
              <div className="social-links">
                <a href="https://discord.gg/gTbaX7aaTg" target="_blank">
                  <img
                    className="social-link"
                    src="config/images/discord-alt.svg"
                    alt=""
                  />
                </a>
                <a href="https://twitter.com/CoolDuckClub" target="_blank">
                  <img
                    className="social-link"
                    src="config/images/twitter.svg"
                    alt=""
                  />
                </a>
                <a
                  href="https://instagram.com/coolduckclub?utm_medium=copy_link"
                  target="_blank"
                >
                  <img
                    className="social-link"
                    src="config/images/instagram.svg"
                    alt=""
                  />
                </a>
              </div>
            </div>
          </div>
        </div>
      );
    }
  }

  return (
    <s.Screen>
      <CDC />
    </s.Screen>
  );
}

export default App;
