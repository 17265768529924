import styled from "styled-components";


// Used for wrapping a page component
export const Screen = styled.div`
  background-color: var(--primary);
  background-image: ${({ image }) => (image ? `url(${image})` : "none")};
  background-size: cover;
  background-position: center;
  width: 100%;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
`;

// Used for providing space between components
export const SpacerXSmall = styled.div`
  height: 8px;
  width: 8px;
`;

// Used for providing space between components
export const SpacerSmall = styled.div`
  height: 16px;
  width: 16px;
`;

// Used for providing space between components
export const SpacerMedium = styled.div`
  height: 24px;
  width: 24px;
`;

// Used for providing space between components
export const SpacerLarge = styled.div`
  height: 32px;
  width: 32px;
`;

// Used for providing a wrapper around a component
export const Container = styled.div`
  display: flex;
  flex: ${({ flex }) => (flex ? flex : 0)};
  flex-direction: ${({ fd }) => (fd ? fd : "column")};
  justify-content: ${({ jc }) => (jc ? jc : "flex-start")};
  align-items: ${({ ai }) => (ai ? ai : "flex-start")};
  background-color: ${({ test }) => (test ? "pink" : "none")};
  width: 100%;
   background-image: ${({ image }) => (image ? `url(${image})` : "none")};
   z-index:20;
`;
export const ContainerMint = styled.div`
  z-index:22;
  grid-column: 6 /  15;
  grid-row: 1 /  6;
  padding-top:90px
`;
export const ContainerMintMobile = styled.div`
  z-index:22;
  grid-column: 7 /  21;
  grid-row: 3 /  10;
  @media only screen and (max-width: 550px) {
    grid-column: 5 /  23;
  }
  @media only screen and (max-width: 410px) {
    grid-column: 3 /  23;
  }
`;
export const ContainerCD = styled.div`
  z-index:20;
  grid-column: 13 /  21;
  grid-row: 1 /  2;
  @media only screen and (max-width: 850px) {
    grid-column: 11 /  24;
    grid-row: 1 /  2;
  }  
`;
export const ContainerVM = styled.div`
  z-index:18;
  grid-column:7/  19;
  grid-row: 1 /  2;
  padding-top:50px;
  @media only screen and (max-width: 850px) {
    grid-column:4 / 22;
    grid-row: 1 / 3;
  }
    `;
export const ContainerDisc = styled.div`
  z-index:19;
  grid-column:6/  20;
  grid-row: 1 /  2;
}
`;
export const ContainerBottom = styled.div`
  display:grid;
  background-color: ${({ test }) => (test ? "pink" : "none")};
  background-image: ${({ image }) => (image ? `url(${image})` : "none")};
  grid-template-columns: repeat(24, 4.1vw [col-start]);
  // grid-template-row: repeat(24, 4.1vh [row-start]);
  padding-top:100px;
  padding-bottom:60px;
`;
export const ContainerBottomMobile = styled.div`
  display:grid;
  background-color: ${({ test }) => (test ? "pink" : "none")};
  background-image: ${({ image }) => (image ? `url(${image})` : "none")};
  grid-template-columns: repeat(24, 4.1vw [col-start]);
  padding-bottom:60px
`;
export const ContainerBottomMobile2 = styled.div`
  display:grid;
  background-color: ${({ test }) => (test ? "pink" : "none")};
  background-image: ${({ image }) => (image ? `url(${image})` : "none")};
  grid-template-columns: repeat(24, 4.1vw [col-start]);
  padding-top:60px
  `;

export const TextTitle = styled.p`
  color: var(--primary-text);
  font-size: 40px;
  font-weight: 500;
  line-height: 1.6;
  letter-spacing:2px;
  @media only screen and (max-width: 1366px) {
    font-size: 40px;
  }
  @media only screen and (max-width: 1266px) {
    font-size: 25px;
  }
  @media only screen and (max-width: 850px) {
    font-size: 30px;
  }
`;

export const TextSubTitle = styled.p`
  color: var(--primary-text);
  font-size: 20px;
  line-height: 1.6;
  padding-top:10px
  // padding-left:250px
`;

export const TextSubTitle2 = styled.p`
  color: white;
  font-size: 18px;
  line-height: 1.6;
  padding-top:10px
`;
export const TextPriceTitle = styled.p`
  color: var(--primary-text);
  font-size: 22px;
  font-weight: 500;
  line-height: 1.6;
`;

export const TextDescription = styled.p`
  color: var(--primary-text);
  font-size: 16px;
  line-height: 1.6;
`;
export const GasFeeDescription = styled.p`
  color: var(--primary-text);
  font-size: 16px;
  // line-height: 1.2;
`;
export const Notice = styled.p`
  color: var(--primary-text);
  font-size: 16px;
  line-height: 1.6;
  grid-column:7/  19;
`;

export const StyledClickable = styled.div`
  :active {
    opacity: 0.6;
  }
`;
export const ContainerTeam = styled.div`
display:grid;
background-color: ${({ test }) => (test ? "pink" : "none")};
background-image: ${({ image }) => (image ? `url(${image})` : "none")};
grid-template-columns: 33% 33% 33%;
grid-template-rows: auto;
padding:20px
// padding:20px 80px
`;
export const ContainerTeamM = styled.div`
display:grid;
background-color: ${({ test }) => (test ? "pink" : "none")};
background-image: ${({ image }) => (image ? `url(${image})` : "none")};
grid-template-columns: 50% 50%;
grid-template-rows: auto;
padding:20px
// padding:20px 80px
`;
export const ContainerTeamItem = styled.div`
  z-index:19;
  grid-auto-columns: 1fr 1fr;
  grid-row: auto;
  padding: 20px 0;
  
}
`;
// export const ContainerTeamItem2 = styled.div`
//   z-index:19;
//   grid-auto-columns: 1fr;
//   grid-row: auto;
//   padding: 20px 0; 
// }
// `;
// export const ContainerTeamItem3 = styled.div`
//   z-index:19;
//   grid-auto-columns: 1fr;
//   grid-row: auto;
//   padding: 20px 0; 
// }
// `;

export const TeamTitle = styled.div`
  color: var(--primary-text);
  font-size: 20px;
  line-height: 1.6;
`;
export const TeamTitleText = styled.p`
  color: var(--primary-text);
  font-size: 24px;
  line-height: 1.6;
  padding:0 60px;
`;
export const TeamTitleTextM = styled.p`
  color: var(--primary-text);
  font-size: 15px;
  line-height: 1.6;
  // padding:0 60px;
`;
// export const TeamImg = styled.div`
//   grid-column:7 / 11;
//   grid-row: 1 / 2;
// `;

