import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
// import CDC from "./pages/CDC"
import store from "./redux/store";
import { Provider } from "react-redux";
import "./styles/reset.css";

// class Cdc extends React.Component{
// render(){
// return(
//   <div id="countdown">
//   <ul>
//       <li><span id="days"></span>days</li>
//       <li><span id="hours"></span>Hours</li>
//       <li><span id="minutes"></span>Minutes</li>
//       <li><span id="seconds"></span>Seconds</li>
//       <img src={CDC}></img>
//   </ul>
// </div>
// );
// }
// }

ReactDOM.render(
  <Provider store={store}>
    <App />
  </Provider>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
